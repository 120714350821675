<template>
    <div class="user_card flex items-center justify-between">
        <Profiler 
            initStatus
            :user="user" />
        <a-tag 
            v-if="isAuthor" 
            color="green">
            Автор
        </a-tag>
        <template v-else>
            <a-tag 
                v-if="isModerator" 
                color="blue">
                Модератор
            </a-tag>
        </template>
    </div>
</template>

<script>
export default {
    name: "MeetingShowDrawerUserCard",
    props: {
        user: {
            type: Object,
            required: true
        },
        meeting: {
            type: Object,
            required: true
        },
        isModerator: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isAuthor() {
            if(this.user.id === this.meeting.author.id)
                return true
            else
                return false
        }
    }
}
</script>